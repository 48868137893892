export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const UNSET_CURRENT_USER = 'UNSET_CURRENT_USER';

export const setCurrentUser = user => {
  return { type: SET_CURRENT_USER, payload: { user } };
};

export const unsetCurrentUser = () => {
  return { type: UNSET_CURRENT_USER };
};
