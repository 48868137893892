import gql from 'graphql-tag';
import {
  EventListFragment,
  FullEventFragment,
  EventLiveBannerFragment,
  EventCalendarFragment,
} from './fragments';

export const MY_EVENTS = gql`
  query myEvents($upcoming: Boolean, $createdByMe: Boolean, $draft: Boolean) {
    myEvents(upcoming: $upcoming, createdByMe: $createdByMe, draft: $draft) {
      ...EventListFragment
    }
  }
  ${EventListFragment}
`;

export const GET_EVENTS = gql`
  query getEvents(
    $date: DateFilter
    $endDate: DateFilter
    $draft: Boolean
    $isConnected: Boolean
    $search: SearchParams
    $pagination: PaginationParams!
    $status: EVENT_STATUS
    $myEvents: Boolean
    $hostId: String
  ) {
    getEvents(
      date: $date
      endDate: $endDate
      draft: $draft
      pagination: $pagination
      isConnected: $isConnected
      search: $search
      status: $status
      myEvents: $myEvents
      hostId: $hostId
    ) {
      collection {
        ...EventCalendarFragment
      }
      totalCount
    }
  }
  ${EventCalendarFragment}
`;

export const EVENT_BY_ID = gql`
  query eventById($_id: String!) {
    eventById(_id: $_id) {
      ...FullEventFragment
    }
  }
  ${FullEventFragment}
`;

export const LIVE_EVENTS = gql`
  query liveEvents($createdByMe: Boolean) {
    liveEvents(createdByMe: $createdByMe) {
      ...EventLiveBannerFragment
    }
  }
  ${EventLiveBannerFragment}
`;

export const GET_CHANNEL_BY_EVENT_ID = gql`
  query getChannelByEventId($eventId: String!) {
    getChannelByEventId(eventId: $eventId) {
      _id
      playbackUrl
      streamKey
      ingestEndpoint
    }
  }
`;

export const GET_STREAM_DATA = gql`
  query getStreamDataByEventId($eventId: String!) {
    getStreamDataByEventId(eventId: $eventId) {
      startTime
      state
      health
      viewerCount
    }
  }
`;

export const GET_SAVED_STREAMS = gql`
  query getSavedStreams($eventId: String!) {
    getSavedStreams(eventId: $eventId) {
      _id
      recordedAt
      duration
      thumbnails
      masterPlaylistURL
    }
  }
`;

export const PAST_EVENTS_QUERY = gql`
  query pastEvents {
    pastEvents {
      _id
      title
      date
      banner
      viewCount
      guests
      savedStreamsCount
      watchedPastRecordings
      hostUserProfile {
        _id
        profile {
          avatar
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_EVENT_DURATION = gql`
  query eventRecordingDuration($eventId: String!) {
    eventRecordingDuration(eventId: $eventId)
  }
`;

export const SEARCH_EVENT_BY_COUPLE = gql`
  query searchEventByCouple(
    $searchParams: SearchParams!
    $limit: Int!
    $skip: Int
  ) {
    searchEventByCouple(
      searchParams: $searchParams
      limit: $limit
      skip: $skip
    ) {
      totalCount
      hasMore
      collection {
        _id
        title
        date
        location
        weddingOfData {
          avatar
        }
      }
    }
  }
`;

export const EVENTS_FOR_LANDING_PAGE = gql`
  query eventsForLandingPage($limit: Int!, $skip: Int) {
    eventsForLandingPage(limit: $limit, skip: $skip) {
      totalCount
      hasMore
      collection {
        _id
        title
        date
        weddingOfData {
          avatar
        }
      }
    }
  }
`;

export const CHECK_USER_HAS_ACCESS_TO_EVENT = gql`
  query checkUserHasAccessToEvent($eventId: String!) {
    checkUserHasAccessToEvent(eventId: $eventId)
  }
`;

export const EVENT_BY_TOKEN = gql`
  query eventByToken($eventId: String!, $token: String!) {
    eventByToken(eventId: $eventId, token: $token) {
      _id
      priceToJoin
      publicLink
    }
  }
`;

export const GET_INVITE_BY_TOKEN = gql`
  query getInviteByToken($token: String!) {
    getInviteByToken(token: $token) {
      _id
      type
      token
      containerId
    }
  }
`;

export const CONNECTED_EVENT_BY_TOKEN = gql`
  query connectedEventByToken($token: String!) {
    connectedEventByToken(token: $token) {
      _id
      priceToJoin
      date
      sponsor {
        name
      }
    }
  }
`;

export const CHECK_VALID_PROMO_CODE = gql`
  query checkValidPromoCode($promoCodeInput: PromoCodeInput!) {
    checkValidPromoCode(promoCodeInput: $promoCodeInput)
  }
`;

export const GET_USERS_MARKED_TO_RECEIVE_EMAILS = gql`
  query getUsersMarkedToReceiveEmails($eventId: String, $pastEvents: Boolean) {
    getUsersMarkedToReceiveEmails(eventId: $eventId, pastEvents: $pastEvents) {
      profile {
        firstName
        lastName
        email
      }
    }
  }
`;

export const EVENT_FEEDS = gql`
  query eventFeeds(
    $eventId: String!
    $pagination: PaginationParams!
    $rootEventFeedId: String
    $promtId: String
  ) {
    eventFeeds(
      eventId: $eventId
      pagination: $pagination
      rootEventFeedId: $rootEventFeedId
      promtId: $promtId
    ) {
      hasMore
      collection {
        _id
        fileIds
        text
        userId
        type
        giftingType
        hideAmount
        promtId
        amount
        subText
        from
        createdAt
        rootEventFeedId
        repliesCount
        commentsCount
        Files {
          url
        }
        reactions {
          userId
          reaction
          user {
            profile {
              avatar
              firstName
              lastName
            }
          }
        }
        Promt {
          _id
          type
          deleted
          text
        }
        User {
          profile {
            avatar
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const EVENT_PROMTS = gql`
  query eventPromts($eventId: String!, $day: Int) {
    eventPromts(eventId: $eventId, day: $day) {
      _id
      eventId
      doneBy
      day
      type
      hour
      deleted
      isActive
      text
      createdAt
      friendlyHour
      isWelcome
      group
    }
  }
`;

export const EVENT_TAGS = gql`
  query eventTags($search: SearchParams) {
    eventTags(search: $search)
  }
`;

export const GET_COMMENTS_FOR_EVENT_FEED = gql`
  query getCommentsForEventFeed(
    $eventFeedId: String!
    $pagination: PaginationParams!
    $parentCommentId: String
  ) {
    getCommentsForEventFeed(
      eventFeedId: $eventFeedId
      pagination: $pagination
      parentCommentId: $parentCommentId
    ) {
      collection {
        _id
        userId
        text
        User {
          _id
          profile {
            avatar
            firstName
            lastName
          }
        }
        repliesCount
        reactions {
          userId
          reaction
          user {
            profile {
              avatar
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

export const EVENT_BY_LINK = gql`
  query eventByLink($eventLink: String!) {
    eventByLink(eventLink: $eventLink) {
      ...FullEventFragment
    }
  }
  ${FullEventFragment}
`;
