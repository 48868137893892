import { useState, useEffect } from 'react';

const useDetectIos = () => {
  const [isIos, setIos] = useState(false);
  useEffect(() => {
    setIos(!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform));
  }, []);

  return isIos;
};

export default useDetectIos;
