import gql from 'graphql-tag';

export const USER_SIGN_UP = gql`
  mutation userSignUp(
    $email: String!
    $firstName: String!
    $lastName: String!
    $password: String!
  ) {
    userSignUp(
      firstName: $firstName
      lastName: $lastName
      password: $password
      email: $email
    ) {
      token
      user {
        profile {
          firstName
          lastName
          email
          avatar
        }
      }
    }
  }
`;

export const USER_SIGN_IN = gql`
  mutation userSignIn($email: String!, $password: String!, $role: UserRoles) {
    userSignIn(password: $password, email: $email, role: $role) {
      token
      user {
        _id
        roles
        promotedBy {
          userId
        }
        profile {
          firstName
          lastName
          email
          avatar
        }
      }
    }
  }
`;

export const USER_SIGN_OUT = gql`
  mutation userSignOut {
    userSignOut
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation updateUserProfile(
    $firstName: String
    $lastName: String
    $email: String
    $avatar: String
    $userId: String
    $addressLine1: String
    $addressLine2: String
    $roles: [UserRoles]
    $phoneNumber: String
    $password: String
  ) {
    updateUserProfile(
      firstName: $firstName
      lastName: $lastName
      email: $email
      avatar: $avatar
      userId: $userId
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      roles: $roles
      phoneNumber: $phoneNumber
      password: $password
    ) {
      _id
      profile {
        firstName
        lastName
        email
        avatar
        bio
        city
        state
        socials {
          instagram
          facebook
          twitter
          linkedIn
        }
        website
        phoneNumber
      }
    }
  }
`;

export const USER_SIGN_IN_WITH_TOKEN = gql`
  mutation userSignInWithToken($token: String!) {
    userSignInWithToken(token: $token) {
      token
      user {
        _id
        profile {
          firstName
          lastName
          email
          avatar
        }
      }
    }
  }
`;

export const USER_SIGN_IN_WITH_PUBLIC_TOKEN = gql`
  mutation userSignInWithPublicToken(
    $token: String!
    $userName: String
    $email: String
  ) {
    userSignInWithPublicToken(
      token: $token
      userName: $userName
      email: $email
    ) {
      token
      user {
        _id
        profile {
          firstName
          lastName
          email
          avatar
        }
      }
    }
  }
`;

export const SIGN_IN_WITH_PUBLIC_TOKEN = gql`
  mutation userSignInWithPublicToken(
    $token: String!
    $userName: String
    $email: String
  ) {
    userSignInWithPublicToken(
      token: $token
      userName: $userName
      email: $email
    ) {
      token
      user {
        _id
        profile {
          firstName
          lastName
          email
          avatar
        }
      }
    }
  }
`;

export const USER_SIMPLE_SIGN_ON = gql`
  mutation userSimpleSignOn(
    $name: String
    $email: String
    $password: String
    $token: String
    $eventId: String
  ) {
    userSimpleSignOn(
      name: $name
      email: $email
      password: $password
      token: $token
      eventId: $eventId
    ) {
      token
      user {
        _id
        profile {
          firstName
          lastName
          email
          avatar
        }
      }
    }
  }
`;

export const PROMOTE_USER_ROLE = gql`
  mutation promoteUserRole($email: String!, $role: UserRoles!) {
    promoteUserRole(role: $role, email: $email) {
      _id
      profile {
        firstName
        lastName
        email
        avatar
      }
    }
  }
`;

export const RECOVER_PASSWORD = gql`
  mutation recoverPassword($email: String!) {
    recoverPassword(email: $email)
  }
`;

export const SET_PASSWORD = gql`
  mutation setPassword(
    $token: String
    $oldPassword: String
    $password: String!
  ) {
    setPassword(token: $token, oldPassword: $oldPassword, password: $password) {
      token
      user {
        _id
        profile {
          firstName
          lastName
          email
          avatar
        }
      }
    }
  }
`;

export const END_OTHER_USER_SESSIONS = gql`
  mutation endOtherUserSessions {
    endOtherUserSessions {
      token
      user {
        _id
        profile {
          firstName
          lastName
          email
          avatar
        }
      }
    }
  }
`;

export const USER_INVITE_PARTNER = gql`
  mutation inviteUser(
    $roles: [UserRoles!]
    $firstName: String!
    $lastName: String!
    $addressLine1: String
    $addressLine2: String
    $city: String
    $state: String
    $country: String
    $zip: String
    $email: String!
    $dateOfBirth: Date
    $partnership: PartnershipInput
  ) {
    inviteUser(
      roles: $roles
      firstName: $firstName
      lastName: $lastName
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      city: $city
      state: $state
      country: $country
      zip: $zip
      email: $email
      dateOfBirth: $dateOfBirth
      partnership: $partnership
    ) {
      _id
    }
  }
`;

export const GENERATE_STRIPE_DASHBOARD_LINK_FOR_USER = gql`
  mutation generateStripeDashboardLinkForUser {
    generateStripeDashboardLinkForUser {
      url
    }
  }
`;

export const GENERATE_STRIPE_OAUTH_LINK_FOR_PARTNER = gql`
  mutation generateStripeOauthLinkForPartner {
    generateStripeOauthLinkForPartner {
      url
    }
  }
`;

export const REMOVE_USER_ROLE = gql`
  mutation removeUserRole($userId: String!, $role: UserRoles!) {
    removeUserRole(userId: $userId, role: $role) {
      _id
    }
  }
`;

export const CONFIRM_USER_EMAIL = gql`
  mutation confirmUserEmail($token: String!) {
    confirmUserEmail(token: $token) {
      token
      user {
        _id
        profile {
          firstName
          lastName
          email
          avatar
        }
        roles
        tokenVersion
      }
    }
  }
`;

export const SET_ME_ONLINE = gql`
  mutation setMeOnline {
    setMeOnline
  }
`;

export const SEND_EMAIL_CONFIRMATION = gql`
  mutation sendEmailConfirmation($email: String!) {
    sendEmailConfirmation(email: $email)
  }
`;

export const REMOVE_USER = gql`
  mutation removeUser($userId: String) {
    removeUser(userId: $userId)
  }
`;

export const USER_SIGN_IN_GOOGLE = gql`
  mutation signInWithGoogle($token: String!) {
    signInWithGoogle(token: $token) {
      token
      user {
        profile {
          firstName
          lastName
          email
          avatar
        }
      }
    }
  }
`;

export const USER_SIGN_IN_FACEBOOK = gql`
  mutation signInWithFacebook($token: String!) {
    signInWithFacebook(token: $token) {
      token
      user {
        profile {
          firstName
          lastName
          email
          avatar
        }
      }
    }
  }
`;

export const RENEW_PARTNERSHIP = gql`
  mutation renewPartnership(
    $partnership: RenewedPartnershipInput!
    $userId: String!
  ) {
    renewPartnership(partnership: $partnership, userId: $userId) {
      _id
      term
      revenue
      userId
      createdBy
      createdAt
    }
  }
`;

export const DELETE_RENEWED_PARTNERSHIP = gql`
  mutation deleteRenewedPartnership($_id: String!) {
    deleteRenewedPartnership(_id: $_id)
  }
`;

export const USER_SIGN_IN_APPLE = gql`
  mutation signInWithApple($code: String!, $appleUser: AppleUserInput!) {
    signInWithApple(code: $code, appleUser: $appleUser) {
      token
      user {
        profile {
          firstName
          lastName
          email
          avatar
        }
      }
    }
  }
`;

export const SEND_CONTACT_US_EMAIL = gql`
  mutation sendContactUsEmail($user: UserDataInput!, $text: String!) {
    sendContactUsEmail(user: $user, text: $text)
  }
`;

export const CONNECT_WITH_TEAM_EMAIL = gql`
  mutation connectWithTeamEmail(
    $user: ConnectWithTeamUserInput!
    $message: String
  ) {
    connectWithTeamEmail(user: $user, message: $message)
  }
`;

export const CREATE_USER = gql`
  mutation createUser(
    $firstName: String!
    $lastName: String
    $email: String
    $phoneNumber: String
    $password: String!
    $avatar: String
    $roles: [UserRoles!]
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      password: $password
      email: $email
      phoneNumber: $phoneNumber
      avatar: $avatar
      roles: $roles
    ) {
      _id
      roles
    }
  }
`;
