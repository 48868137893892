import Cookies from 'js-cookie';

export const getCookie = (name) => {
  return Cookies.get(name);
};

export const setCookie = (key, value, params = {}) => {
  Cookies.set(key, value, {
    sameSite: 'lax',
    domain: location.hostname,
    expires: 365,
    ...params,
  });
};

export const deleteCookie = (key) => {
  Cookies.remove(key, { sameSite: 'lax', domain: location.hostname });
};

export const setAuthCookies = (token) => {
  const domain = process.env.URL.replace(/http:\/\/|https:\/\//, '')
    .split('.')
    .slice(-2)
    .join('.');

  setCookie('token', token);
  setCookie('serverToken', token, { domain });
};

export const unsetAuthCookies = () => {
  deleteCookie('token');
  deleteCookie('serverToken');
};
