import {
  SET_EVENT_ID,
  UNSET_EVENT_ID,
  SET_EVENT_DATA,
  UNSET_EVENT_DATA,
} from '../actions/event';

const initialState = {
  eventId: null,
  eventData: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
  case SET_EVENT_ID:
    return { ...state, eventId: action.payload.eventId };
  case UNSET_EVENT_ID:
    return { ...state, eventId: null };
  case SET_EVENT_DATA:
    return { ...state, eventData: action.payload.data };
  case UNSET_EVENT_DATA:
    return { ...state, eventData: null };
  default:
    return state;
  }
}
