export const SET_USER_SENT_PAYMENT = 'SET_USER_SENT_PAYMENT';
export const SET_USER_SENT_AUDIO_MESSAGE = 'SET_USER_SENT_AUDIO_MESSAGE';
export const SET_USER_SENT_VIDEO_MESSAGE = 'SET_USER_SENT_VIDEO_MESSAGE';
export const UNSET_USER_SENT_MESSAGE = 'UNSET_USER_SENT_MESSAGE';

export const setUserSentPayment = (eventId, sentPayment) => {
  return { type: SET_USER_SENT_PAYMENT, payload: { eventId, sentPayment } };
};

export const setUserSentAudioMessage = (eventId, sentAudioMessage) => {
  return { type: SET_USER_SENT_AUDIO_MESSAGE, payload: { eventId, sentAudioMessage } };
};

export const setUserSentVideoMessage = (eventId, sentVideoMessage) => {
  return { type: SET_USER_SENT_VIDEO_MESSAGE, payload: { eventId, sentVideoMessage } };
};

export const unsetUserSentMessage = () => {
  return { type: UNSET_USER_SENT_MESSAGE };
};
