import gql from 'graphql-tag';

export const UPSERT_EVENT_MUTATION = gql`
  mutation upsertEvent(
    $_id: String
    $title: String!
    $weddingOfData: [WeddingMemberInput!]
    $location: String
    $privacy: String
    $host: WeddingMemberInput
    $date: Date
    $startDate: Date
    $groups: [String!]
    $isWhatsApp: Boolean
    $endDate: Date
    $coHostsData: [WeddingMemberInput!]
    $about: String
    $banner: String
    $isDraft: Boolean
    $itinerary: [ItineraryInput!]
    $tablesData: [TableInput!]
    $timezone: String
    $emailBody: String
    $ceremonyAddress: String
    $airport: String
    $receptionAddress: String
    $hotels: [String!]
    $polaroidOrderFormLink: String
    $memoryBoxOrderFormLink: String
    $allowGifting: Boolean
    $locations: [EventLocationInput!]
    $eventPromts: [UpsertEventPromtInput!]
    $registryLink: String
    $showGiftingAmount: Boolean
    $eventLink: String
  ) {
    upsertEvent(
      ceremonyAddress: $ceremonyAddress
      airport: $airport
      receptionAddress: $receptionAddress
      hotels: $hotels
      _id: $_id
      title: $title
      weddingOfData: $weddingOfData
      location: $location
      privacy: $privacy
      date: $date
      startDate: $startDate
      groups: $groups
      isWhatsApp: $isWhatsApp
      endDate: $endDate
      host: $host
      coHostsData: $coHostsData
      about: $about
      banner: $banner
      isDraft: $isDraft
      itinerary: $itinerary
      tablesData: $tablesData
      timezone: $timezone
      emailBody: $emailBody
      memoryBoxOrderFormLink: $memoryBoxOrderFormLink
      polaroidOrderFormLink: $polaroidOrderFormLink
      allowGifting: $allowGifting
      locations: $locations
      eventPromts: $eventPromts
      registryLink: $registryLink
      showGiftingAmount: $showGiftingAmount
      eventLink: $eventLink
    ) {
      stripeAccountLink {
        url
      }
      event {
        _id
        allowGifting
        eventPromts {
          _id
        }
      }
    }
  }
`;

export const UPSERT_EVENTS_MUTATION = gql`
  mutation upsertEvents(
    $host: WeddingMemberInput
    $events: [UpsertEventInput!]!
  ) {
    upsertEvents(host: $host, events: $events) {
      event {
        _id
        allowGifting
        eventPromts {
          _id
        }
      }
    }
  }
`;

export const END_STREAM = gql`
  mutation endStream($channelId: String!) {
    endStream(channelId: $channelId)
  }
`;

export const STRIPE_LINK = gql`
  mutation generateStripeAccountLinkForEvent(
    $eventId: String!
    $type: ACCOUNT_LINK_TYPE!
  ) {
    generateStripeAccountLinkForEvent(eventId: $eventId, type: $type) {
      url
    }
  }
`;

export const STRIPE_DASHBOARD_LINK = gql`
  mutation generateStripeDashboardLinkForEvent($eventId: String!) {
    generateStripeDashboardLinkForEvent(eventId: $eventId) {
      url
    }
  }
`;

export const COUNT_EVENTS_STATS = gql`
  mutation countEventStats($eventId: String!) {
    countEventStats(eventId: $eventId) {
      _id
    }
  }
`;

export const REQUEST_EVENT_LINK = gql`
  mutation requestEventLink(
    $firstName: String
    $lastName: String
    $email: String
    $eventId: String!
  ) {
    requestEventLink(
      firstName: $firstName
      lastName: $lastName
      email: $email
      eventId: $eventId
    )
  }
`;

export const ADD_EVENT_REACTION = gql`
  mutation addEventReaction($eventId: String!, $type: EVENT_REACTION_TYPE) {
    addEventReaction(eventId: $eventId, type: $type)
  }
`;

export const DELETE_EVENT = gql`
  mutation deleteEvent($eventId: String!) {
    deleteEvent(eventId: $eventId)
  }
`;

export const UPDATE_SUBMITTED_EVENT = gql`
  mutation updateSubmittedEvent(
    $eventId: String!
    $date: Date
    $endDate: Date
    $timezone: String
    $itinerary: [ItineraryInput!]
  ) {
    updateSubmittedEvent(
      eventId: $eventId
      date: $date
      endDate: $endDate
      timezone: $timezone
      itinerary: $itinerary
    ) {
      _id
    }
  }
`;

export const ADD_EVENT_GUESTS = gql`
  mutation addEventGuests(
    $eventId: String!
    $guests: [EventMemberInput!]!
    $chatId: String
    $newChatName: String
  ) {
    addEventGuests(
      eventId: $eventId
      guests: $guests
      chatId: $chatId
      newChatName: $newChatName
    )
  }
`;

export const ADD_ME_TO_EMAIL_ABOUT_NEW_EVENTS = gql`
  mutation addMeToEmailAboutNewEvents($eventId: String!) {
    addMeToEmailAboutNewEvents(eventId: $eventId)
  }
`;

export const CREATE_EVENT_FEED = gql`
  mutation createEventFeed(
    $eventId: String!
    $text: String
    $fileIds: [String!]
    $promtId: String
    $type: EventFeedType
  ) {
    createEventFeed(
      eventId: $eventId
      text: $text
      fileIds: $fileIds
      promtId: $promtId
      type: $type
    ) {
      _id
    }
  }
`;

export const ADD_FEED_REACTION = gql`
  mutation addFeedReaction($eventFeedId: String!, $reaction: String) {
    addFeedReaction(eventFeedId: $eventFeedId, reaction: $reaction) {
      _id
    }
  }
`;

export const REMOVE_FEED_REACTION = gql`
  mutation removeFeedReaction($eventFeedId: String!, $reaction: String) {
    removeFeedReaction(eventFeedId: $eventFeedId, reaction: $reaction) {
      _id
    }
  }
`;

export const CONTACT_TO_JOIN_EVENT = gql`
  mutation contactToJoinEvent(
    $eventId: String!
    $users: [WeddingMemberInput!]!
  ) {
    contactToJoinEvent(eventId: $eventId, users: $users)
  }
`;

export const ADD_EVENT_PROMT = gql`
  mutation addEventPromt(
    $eventId: String!
    $text: String!
    $day: Int!
    $hour: Float!
    $type: EVENT_PROMPT_TYPES!
  ) {
    addEventPromt(
      eventId: $eventId
      text: $text
      day: $day
      hour: $hour
      type: $type
    ) {
      _id
    }
  }
`;

export const SEND_SMS_TO_ALL_EVENT_GUESTS = gql`
  mutation sendSmsToAllEventGuests($eventId: String!, $text: String!) {
    sendSmsToAllEventGuests(eventId: $eventId, text: $text)
  }
`;

export const ADD_COMMENT_TO_EVENT_FEED = gql`
  mutation addCommentToEventFeed(
    $eventFeedId: String!
    $text: String!
    $parentCommentId: String
    $eventId: String!
  ) {
    addCommentToEventFeed(
      eventFeedId: $eventFeedId
      text: $text
      eventId: $eventId
      parentCommentId: $parentCommentId
    ) {
      text
      _id
      reactions {
        reaction
        user {
          profile {
            avatar
            firstName
            lastName
          }
        }
      }
      User {
        profile {
          firstName
          lastName
        }
      }
    }
  }
`;

export const ADD_COMMENT_REACTION = gql`
  mutation addCommentReaction($commentId: String!, $reaction: String!) {
    addCommentReaction(commentId: $commentId, reaction: $reaction) {
      _id
    }
  }
`;

export const DELETE_COMMENT_REACTION = gql`
  mutation deleteCommentReaction($commentId: String!, $reaction: String!) {
    deleteCommentReaction(commentId: $commentId, reaction: $reaction) {
      _id
    }
  }
`;
