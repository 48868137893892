export const ROUTES = {
  ROOT: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  CHOOSE_NEW_PASSWORD: '/choose-new-password',
  CREATE_PASSWORD: '/create-password',
  HOME: '/home',
  MY_EVENTS: '/my-events',
  MY_ADDRESS_BOOK: '/my-address-book',
  PAST_EVENTS: '/past-events',
  CONTACTS: '/contacts',
  CREATE_CONTACT: '/create-contact',
  CREATE_TABLE: '/create-table',
  TABLES: '/tables',
  EVENT: '/event',
  PAST_EVENT: '/past-event',
  CREATE_EVENT: '/create-event',
  EDIT_EVENT: '/edit-event',
  SETTINGS: '/settings',
  PROFILE: '/profile',
  NOTIFICATIONS: '/notifications',
  PRIVACY_POLICY: '/privacy-policy',
  ADDRESS_BOOK: '/address-book',
  FIND_COUPLE: '/find-couple',
  PARTNERSHIPS: '/partnerships',
  FAQ: '/faq',
  CONTACT_US: '/contact-us',
  NEW_CONTACT: '/new-contact',
  EVENT_DETAILS: '/event-details',
  STRIPE_OAUTH: '/stripe-oauth',
  VERIFY_EMAIL: '/verify-email',
  WEDDING_EXPERIENCES: '/wedding-experiences',
  YOUR_WEDDING: '/your-wedding',
  REFERRAL_LINK: '/referral-link',
  PAYMENT_EVENT: '/payment-event',
  EVENT_PROPERTIES: '/event-properties',
  GIFT: '/gift',
  POST_MEDIA: '/post-media',
  POST_AVATAR: '/post-avatar',
  POST_AUDIO: '/post-audio',
  ALL_REPLIES: '/all-replies',
  GUEST_LIST: '/guest-list',
  REACTIONS: '/reactions',
  POST: '/post',
};
