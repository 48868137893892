import {
  SET_USER_SENT_PAYMENT,
  SET_USER_SENT_AUDIO_MESSAGE,
  SET_USER_SENT_VIDEO_MESSAGE,
  UNSET_USER_SENT_MESSAGE,
} from '../actions/events';

const initialState = {
  usersEvents: [],
};

export default function (state = initialState, action) {
  const existsEvent =
    action?.payload?.eventId &&
    state.usersEvents.find((event) => event.eventId === action.payload.eventId);

  switch (action.type) {
  case SET_USER_SENT_PAYMENT:
    return {
      ...state,
      usersEvents: existsEvent
        ? state.usersEvents.map((event) =>
          event.eventId === action.payload.eventId
            ? { ...event, sentPayment: action.payload.sentPayment }
            : event
        )
        : [
          ...state.usersEvents,
          {
            eventId: action.payload.eventId,
            sentPayment: action.payload.sentPayment,
            sentAudioMessage: false,
            sentVideoMessage: false,
          },
        ],
    };
  case SET_USER_SENT_AUDIO_MESSAGE:
    return {
      ...state,
      usersEvents: existsEvent
        ? state.usersEvents.map((event) =>
          event.eventId === action.payload.eventId
            ? {
              ...event,
              sentAudioMessage: action.payload.sentAudioMessage,
            }
            : event
        )
        : [
          ...state.usersEvents,
          {
            eventId: action.payload.eventId,
            sentAudioMessage: action.payload.sentAudioMessage,
            sentPayment: false,
            sentVideoMessage: false,
          },
        ],
    };
  case SET_USER_SENT_VIDEO_MESSAGE:
    return {
      ...state,
      usersEvents: existsEvent
        ? state.usersEvents.map((event) =>
          event.eventId === action.payload.eventId
            ? {
              ...event,
              sentVideoMessage: action.payload.sentVideoMessage,
            }
            : event
        )
        : [
          ...state.usersEvents,
          {
            eventId: action.payload.eventId,
            sentVideoMessage: action.payload.sentVideoMessage,
            sentPayment: false,
            sentAudioMessage: false,
          },
        ],
    };
  case UNSET_USER_SENT_MESSAGE:
    return { ...state, usersEvents: [] };

  default:
    return state;
  }
}
