import { SET_CURRENT_USER, UNSET_CURRENT_USER } from '../actions/users';

const initialState = {
  currentUser: null,
  collection: []
};

export default function(state = initialState, action) {
  switch (action.type) {
  case SET_CURRENT_USER:
    return { ...state, currentUser: action.payload.user };
  case UNSET_CURRENT_USER:
    return { ...state, currentUser: null };
  default:
    return state;
  }
}
