import gql from 'graphql-tag';

export const GET_SIGNED_URL = gql`
  query getSignedUrl(
    $fileName: String!
    $path: String!
    $contentType: String!
  ) {
    getSignedUrl(path: $path, contentType: $contentType, fileName: $fileName) {
      putUrl
      file {
        _id
        url
      }
    }
  }
`;

export const GET_SIGNED_URLS = gql`
  query getSignedUrls(
    $files: [FileInput!]!
  ) {
    getSignedUrls(files: $files) {
      putUrl
      file {
        _id
        url
      }
    }
  }
`;

export const GET_FILE_BY_ID = gql`
  query getFileById($fileId: String!) {
    getFileById(fileId: $fileId) {
      _id
      name
      contentType
      url
      bucket
      createdAt
      createdBy
    }
  }
`;
