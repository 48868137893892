import { createStore } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import combinedReducers from './reducers/index';

const persistConfig = {
  key: 'primary',
  storage,
  whitelist: ['events', 'users', 'event', 'cookies'],
};

const persistedReducer = persistReducer(persistConfig, combinedReducers);

export default createStore(
  persistedReducer,
  window.__PRELOADED_STATE__,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
