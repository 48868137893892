import gql from 'graphql-tag';

export const EVENT_REACTION = gql`
  subscription eventReaction($eventId: String!) {
    eventReaction(eventId: $eventId) {
      _id
      type
      userId
    }
  }
`;

export const EVENT_FEED_ADDED = gql`
  subscription eventFeedAdded($eventId: String!) {
    eventFeedAdded(eventId: $eventId) {
      _id
      fileIds
      text
      userId
      type
      giftingType
      promtId
      from
      createdAt
      rootEventFeedId
      repliesCount
      Files {
        url
      }
      reactions {
        userId
        reaction
      }
      User {
        profile {
          avatar
          firstName
          lastName
        }
      }
      Promt {
        _id
        type
        text
      }
    }
  }
`;

export const EVENT_PROMT_ADDED = gql`
  subscription eventPromtAdded($eventId: String!) {
    eventPromtAdded(eventId: $eventId) {
      _id
      type
      doneBy
      day
      text
      createdAt
      isActive
    }
  }
`;
