export const SET_EVENT_ID = 'SET_EVENT_ID';
export const UNSET_EVENT_ID = 'UNSET_EVENT_ID';
export const SET_EVENT_DATA = 'SET_EVENT_DATA';
export const UNSET_EVENT_DATA = 'UNSET_EVENT_DATA';

export const setEventId = eventId => {
  return { type: SET_EVENT_ID, payload: { eventId } };
};

export const setEventData = data => {
  return { type: SET_EVENT_DATA, payload: { data } };
};

export const unsetEventId = () => {
  return { type: UNSET_EVENT_ID };
};

export const unsetEventData = () => {
  return { type: UNSET_EVENT_DATA };
};
