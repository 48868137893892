/* eslint-disable no-console */
// import React, { useEffect, useState } from 'react';
// import { render } from 'react-dom';
// import { ApolloProvider } from '@apollo/client/react/context';
// import {
//   Route,
//   BrowserRouter,
//   Switch,
//   useHistory,
//   Redirect,
// } from 'react-router-dom';
// import { Provider, useDispatch, shallowEqual, useSelector } from 'react-redux';
// import { useMutation } from '@apollo/client';
// import { persistStore } from 'redux-persist';
// import { PersistGate } from 'redux-persist/integration/react';
// import loadable from '@loadable/component';

// import Home from './pages/Home/';

// const PrivacyPolicy = loadable(() => import('./pages/PrivacyPolicy/'));
// const ChooseNewPassword = loadable(() => import('./pages/ChooseNewPassword'));
// const CreatePassword = loadable(() => import('./pages/CreatePassword/'));
// const NewContact = loadable(() => import('./pages/NewContact/'));
// const JoinEventByPublicLinkPopup = loadable(() =>
//   import('./components/JoinEventByPublicLinkPopup/')
// );
// const JoinConnectedEventByPublicLinkPopup = loadable(() =>
//   import('./components/JoinConnectedEventByPublicLinkPopup/')
// );
// const JoinEventByCashPopup = loadable(() =>
//   import('./components/JoinEventByCashPopup/')
// );
// const Spinner = loadable(() => import('./components/Spinner'));
// const PaymentFeeOption = loadable(() => import('./pages/PaymentFeeOption/'));

// import { useLogout } from './hooks/useLogout';

// import { apolloClient } from './apollo';
// import {
//   CHECK_USER_HAS_ACCESS_TO_EVENT,
//   CONNECTED_EVENT_BY_TOKEN,
//   CHECK_VALID_PROMO_CODE,
// } from './apollo/events/queries';
// import { CURRENT_USER } from './apollo/user/queries';
// import {
//   GENERATE_STRIPE_OAUTH_LINK_FOR_PARTNER,
//   CONFIRM_USER_EMAIL,
//   SET_ME_ONLINE,
//   USER_SIGN_IN_WITH_PUBLIC_TOKEN,
// } from './apollo/user/mutations';

// import store from './redux/store';
// import { setCurrentUser, unsetCurrentUser } from './redux/actions/users';
// import { getCurrentUser } from './redux/selectors/users';

// import PrivateRoute from './routes/protected';

// import { getCookie, setAuthCookies } from './helpers/general';
// import { openInNewWindow } from '/helpers/openInNewWindow';
// import { download } from './helpers/download';

// import {
//   useAnalyticsPage,
//   useAnalyticsIdentifyReduxUser,
// } from './lib/google-analytics.js';
// import { ROUTES } from './lib/constants/routes';
// import { CLIENT_CONSTANTS } from './lib/constants/client';
// import '/styles/reset.scss';
// import '/styles/globals.scss';

// const {
//   TOKEN,
//   SHOW_GIFT_POPUP,
//   PUBLIC,
//   TO_LOGIN,
//   GET_CALENDAR_EVENT,
//   PARTNER_CODE,
//   PARTNERSHIP_CODE,
// } = CLIENT_CONSTANTS;

// const {
//   ROOT,
//   LOGIN,
//   REGISTER,
//   FORGOT_PASSWORD,
//   CHOOSE_NEW_PASSWORD,
//   MY_EVENTS,
//   PRIVACY_POLICY,
//   CREATE_PASSWORD,
//   NEW_CONTACT,
//   STRIPE_OAUTH,
//   VERIFY_EMAIL,
//   REFERRAL_LINK,
//   CREATE_EVENT,
//   EVENT,
//   PAYMENT_EVENT,
//   WEDDING_EXPERIENCES,
// } = ROUTES;

// const App = () => {
//   // Google analytics.page
//   useAnalyticsPage();
//   // Google analytics.identify
//   useAnalyticsIdentifyReduxUser();

//   const userFromRedux = useSelector(getCurrentUser, shallowEqual);
//   const logout = useLogout(LOGIN);
//   const history = useHistory();
//   const [setMeOnline] = useMutation(SET_ME_ONLINE);
//   const [confirmUserEmail] = useMutation(CONFIRM_USER_EMAIL);
//   const [userSignInWithPublicLink] = useMutation(
//     USER_SIGN_IN_WITH_PUBLIC_TOKEN
//   );
//   const [generateStripeOauthLinkForPartner] = useMutation(
//     GENERATE_STRIPE_OAUTH_LINK_FOR_PARTNER
//   );
//   const dispatch = useDispatch();
//   const [isLoading, setIsLoading] = useState(true);
//   const [showSendGiftPopup, setShowSendGiftPopup] = useState(false);
//   const [showJoinEventByPublicLinkPopup, setShowJoinEventByPublicLinkPopup] =
//     useState(false);
//   const [
//     joinConnectedEventByPublicLinkPopup,
//     setShowJoinConnectedEventByPublicLinkPopup,
//   ] = useState(false);
//   const [showJoinEventByCashPopup, setShowJoinEventByCashPopup] =
//     useState(false);
//   const [eventId, setEventId] = useState();
//   const [isDarkMode, setIsDarkMode] = useState(false);

//   const tokenFromCookies = getCookie(TOKEN);

//   const url = window.location.href.replace('%23', '#');
//   const newUrl = new URL(url);
//   const query = new URLSearchParams(newUrl.search);

//   const tokenFromUrl = query.get(TOKEN);
//   const showGiftPopup = query.get(SHOW_GIFT_POPUP);
//   const partnerCodeFromQuery = query.get(PARTNER_CODE);

//   const eventType = newUrl.hash.substr(1);

//   useEffect(() => {
//     if (window.location.pathname.includes(GET_CALENDAR_EVENT)) {
//       download(window.location.pathname);
//     }
//   }, []);

//   useEffect(() => {
//     if (
//       userFromRedux &&
//       userFromRedux?.ghostUser &&
//       !window.location.pathname.includes(`${EVENT}/`)
//     ) {
//       logout();
//     }
//   }, []);

//   useEffect(() => {
//     setMeOnline().catch(({ message }) => console.error(message));
//     const interval = setInterval(() => {
//       setMeOnline().catch(({ message }) => console.error(message));
//     }, 50000);
//     return () => clearInterval(interval);
//   }, []);

//   useEffect(() => {
//     if (window.location.pathname === STRIPE_OAUTH && tokenFromCookies) {
//       generateStripeOauthLinkForPartner()
//         .then(({ data }) =>
//           openInNewWindow(data?.generateStripeOauthLinkForPartner?.url)
//         )
//         .catch(({ message }) => console.error(message));
//     } else if (window.location.pathname === STRIPE_OAUTH && !tokenFromCookies) {
//       location.replace(`${LOGIN}?stripe=${STRIPE_OAUTH}`);
//     }
//   }, []);

//   const correctMutationSetToken =
//     tokenFromUrl && window.location.pathname === VERIFY_EMAIL
//       ? confirmUserEmail
//       : tokenFromUrl && !tokenFromCookies
//         ? userSignInWithPublicLink
//         : null;

//   useEffect(() => {
//     if (showGiftPopup) {
//       setShowSendGiftPopup(true);
//     }
//     if (
//       !tokenFromCookies &&
//       tokenFromUrl &&
//       window.location.pathname === CREATE_PASSWORD
//     ) {
//       setIsLoading(false);
//     } else if (location.pathname === REFERRAL_LINK && partnerCodeFromQuery) {
//       apolloClient
//         .query({
//           query: CHECK_VALID_PROMO_CODE,
//           variables: {
//             promoCodeInput: {
//               code: partnerCodeFromQuery,
//               type: PARTNERSHIP_CODE,
//             },
//           },
//         })
//         .then(({ data: { checkValidPromoCode } = {} }) => {
//           if (checkValidPromoCode && tokenFromCookies) {
//             setIsLoading(false);

//             if (userFromRedux) {
//               dispatch(
//                 setCurrentUser({
//                   ...userFromRedux,
//                   partnerCode: partnerCodeFromQuery,
//                 })
//               );
//             }

//             history.push(`${MY_EVENTS}${CREATE_EVENT}`);
//           } else if (checkValidPromoCode && !tokenFromCookies) {
//             location.replace(`${LOGIN}?partner-code=${partnerCodeFromQuery}`);
//           } else if (!checkValidPromoCode && !tokenFromCookies) {
//             location.replace(ROOT);
//           } else if (!checkValidPromoCode && tokenFromCookies) {
//             setIsLoading(false);
//             history.push(MY_EVENTS);
//           }
//         });
//     } else if (eventType === TO_LOGIN && !tokenFromCookies) {
//       setIsLoading(false);
//       history.push({
//         pathname: LOGIN,
//         hash: window.location.pathname,
//       });
//     } else if (eventType === PUBLIC && !tokenFromCookies) {
//       // check type of event
//       apolloClient
//         .query({
//           query: CONNECTED_EVENT_BY_TOKEN,
//           variables: {
//             token: tokenFromUrl,
//           },
//         })
//         .then(({ data }) => {
//           const {
//             connectedEventByToken: {
//               date,
//               priceToJoin,
//               sponsor: { name } = {},
//             } = {},
//           } = data;
//           const eventWasMoreThanWeekAgo =
//             new Date().getTime() - date > 1000 * 60 * 60 * 24 * 7;

//           if (eventWasMoreThanWeekAgo) {
//             setIsLoading(false);
//             location.replace(WEDDING_EXPERIENCES);
//           } else {
//             const eventIdFromUrl = window.location.pathname
//               .split('/')
//               .filter(Boolean)
//               .slice(-1)[0];
//             if (priceToJoin) {
//               setEventId(eventIdFromUrl);
//               setShowJoinEventByCashPopup(true);
//             } else if (name) {
//               setEventId(eventIdFromUrl);
//               setShowJoinConnectedEventByPublicLinkPopup(true);
//               setIsLoading(false);
//             } else {
//               setShowJoinEventByPublicLinkPopup(true);
//               setIsLoading(false);
//             }
//           }
//         })
//         .catch(({ message }) => console.error(message))
//         .finally(() => setIsLoading(false));
//     } else if (
//       tokenFromUrl &&
//       (window.location.pathname === VERIFY_EMAIL || !tokenFromCookies)
//     ) {
//       correctMutationSetToken({
//         variables: {
//           token: tokenFromUrl,
//         },
//       })
//         .then(({ data }) => {
//           const currentUser =
//             data?.userSignInWithPublicToken || data?.confirmUserEmail;
//           if (currentUser) {
//             const { user, token } = currentUser || {};
//             setAuthCookies(token);
//             dispatch(setCurrentUser({ ...userFromRedux, ...user }));
//             setIsLoading(false);
//             const partnerCode = localStorage.getItem('partnerCode');
//             if (partnerCode) {
//               dispatch(
//                 setCurrentUser({ ...userFromRedux, ...user, partnerCode })
//               );
//               localStorage.removeItem('partnerCode');
//               history.replace(`${MY_EVENTS}${CREATE_EVENT}`);
//             } else {
//               dispatch(setCurrentUser({ ...userFromRedux, ...user }));
//               history.replace(window.location.pathname);
//             }
//           }
//         })
//         .catch(({ message }) => {
//           console.error(message);
//           setIsLoading(false);
//         });
//     } else if (tokenFromUrl && tokenFromCookies && eventType === PUBLIC) {
//       // check type of event
//       apolloClient
//         .query({
//           query: CONNECTED_EVENT_BY_TOKEN,
//           variables: {
//             token: tokenFromUrl,
//           },
//         })
//         .then(async ({ data }) => {
//           const { connectedEventByToken: { date, priceToJoin, _id } = {} } =
//             data;

//           const eventWasMoreThanWeekAgo =
//             new Date().getTime() - date > 1000 * 60 * 60 * 24 * 7;

//           if (eventWasMoreThanWeekAgo) {
//             location.replace(WEDDING_EXPERIENCES);
//           } else if (priceToJoin) {
//             await apolloClient
//               .query({
//                 query: CHECK_USER_HAS_ACCESS_TO_EVENT,
//                 variables: { eventId: _id },
//               })
//               .then(async ({ data }) => {
//                 const { checkUserHasAccessToEvent } = data;

//                 // if user has access to event - set data to redux and redirect to event
//                 if (checkUserHasAccessToEvent && !userFromRedux?.ghostUser) {
//                   await userSignInWithPublicLink({
//                     variables: {
//                       token: tokenFromUrl,
//                     },
//                   })
//                     .then((res) => {
//                       const { user, token } =
//                         res.data?.userSignInWithPublicToken || {};
//                       if (user) {
//                         setAuthCookies(token);
//                         dispatch(
//                           setCurrentUser({
//                             ...user,
//                             ghostUser: false,
//                             ...userFromRedux,
//                           })
//                         );
//                         setShowJoinEventByPublicLinkPopup(false);
//                         history.replace(window.location.pathname);
//                       }
//                       setIsLoading(false);
//                     })
//                     .catch(({ message }) => {
//                       console.error(message);
//                       setIsLoading(false);
//                     });
//                 } else if (
//                   checkUserHasAccessToEvent &&
//                   userFromRedux?.ghostUser
//                 ) {
//                   setShowJoinEventByPublicLinkPopup(false);
//                   history.replace(window.location.pathname);
//                   setIsLoading(false);
//                 } else {
//                   // if user has no access to event - set data to redux
//                   apolloClient
//                     .query({ query: CURRENT_USER })
//                     .then((res) => {
//                       const currentUser = res.data?.currentUser;
//                       if (currentUser) {
//                         dispatch(
//                           setCurrentUser({
//                             ...userFromRedux,
//                             ...currentUser,
//                           })
//                         );
//                       }
//                     })
//                     .catch(({ message }) => {
//                       console.error(message);
//                     });
//                   setEventId(_id);
//                   setShowJoinEventByCashPopup(true);
//                 }
//               })
//               .catch(({ message }) => console.error(message));
//           } else {
//             await apolloClient
//               .query({ query: CURRENT_USER })
//               .then(async (res) => {
//                 const currentUser = res.data?.currentUser;
//                 if (currentUser) {
//                   dispatch(
//                     setCurrentUser({ ...userFromRedux, ...currentUser })
//                   );
//                   const { ghostUser } = userFromRedux;
//                   if (!ghostUser) {
//                     await userSignInWithPublicLink({
//                       variables: {
//                         token: tokenFromUrl,
//                       },
//                     })
//                       .then((res) => {
//                         const { user, token } =
//                           res.data?.userSignInWithPublicToken || {};
//                         if (user) {
//                           setAuthCookies(token);
//                           dispatch(
//                             setCurrentUser({ ...userFromRedux, ...user })
//                           );
//                           setShowJoinEventByPublicLinkPopup(false);
//                           history.replace(window.location.pathname);
//                         }
//                         setIsLoading(false);
//                       })
//                       .catch(({ message }) => {
//                         console.error(message);
//                         setIsLoading(false);
//                       });
//                   } else {
//                     history.replace(window.location.pathname);
//                   }
//                 }
//               })
//               .catch(({ message }) => console.error(message))
//               .finally(() => setIsLoading(false));
//           }
//         })
//         .catch(({ message }) => console.error(message))
//         .finally(() => setIsLoading(false));
//     } else {
//       if (tokenFromUrl && tokenFromCookies) {
//         setIsLoading(false);
//         history.replace(window.location.pathname);
//       } else if (!userFromRedux) {
//         apolloClient
//           .query({ query: CURRENT_USER })
//           .then((res) => {
//             const currentUser = res.data?.currentUser;
//             if (currentUser) {
//               const partnerCode = localStorage.getItem('partnerCode');

//               dispatch(
//                 setCurrentUser({
//                   ...currentUser,
//                   ghostUser: false,
//                   partnerCode,
//                 })
//               );
//               localStorage.removeItem('partnerCode');
//               if (
//                 [REGISTER, LOGIN, FORGOT_PASSWORD, CREATE_PASSWORD].includes(
//                   window.location.pathname
//                 )
//               ) {
//                 history.push(MY_EVENTS);
//               }
//             }
//           })
//           .catch(({ message }) => {
//             dispatch(unsetCurrentUser());
//             console.error(message);
//           })
//           .finally(() => setIsLoading(false));
//       } else {
//         setIsLoading(false);
//       }
//     }
//   }, []);

//   // if (isLoading) {
//   //   return <Spinner />;
//   // }

//   if (showJoinEventByPublicLinkPopup)
//     return (
//       <JoinEventByPublicLinkPopup
//         {...{
//           setShowJoinEventByPublicLinkPopup,
//           tokenFromUrl,
//         }}
//       />
//     );

//   if (joinConnectedEventByPublicLinkPopup)
//     return (
//       <JoinConnectedEventByPublicLinkPopup
//         {...{
//           setShowJoinConnectedEventByPublicLinkPopup,
//           tokenFromUrl,
//           eventId,
//         }}
//       />
//     );

//   if (showJoinEventByCashPopup)
//     return (
//       <JoinEventByCashPopup
//         {...{
//           setShowJoinEventByCashPopup,
//           tokenFromUrl,
//           eventId,
//         }}
//       />
//     );

//   return (
//     <Switch>
//       <Route exact path={ROOT}>
//         <Redirect to={MY_EVENTS} />
//       </Route>
//       <Route path={PAYMENT_EVENT}>
//         <PaymentFeeOption />
//       </Route>
//       <Route path={CREATE_PASSWORD}>
//         <CreatePassword {...{ tokenFromUrl }} />
//       </Route>
//       <Route path={NEW_CONTACT}>
//         <NewContact />
//       </Route>
//       <Route path={PRIVACY_POLICY}>
//         <PrivacyPolicy />
//       </Route>
//       <PrivateRoute path={CHOOSE_NEW_PASSWORD} redirectTo={LOGIN}>
//         <ChooseNewPassword />
//       </PrivateRoute>
//       <PrivateRoute path={ROOT} redirectTo={LOGIN}>
//         <Home
//           {...{
//             showSendGiftPopup,
//             isDarkMode,
//             setIsDarkMode,
//           }}
//         />
//       </PrivateRoute>
//       <Route path="*">
//         <Redirect to={MY_EVENTS} />
//       </Route>
//     </Switch>
//   );
// };

// const persistor = persistStore(store, {}, function () {
//   persistor.persist();
// });

// render(
//   <Provider store={store}>
//     <PersistGate loading={null} persistor={persistor}>
//       <ApolloProvider client={apolloClient}>
//         <BrowserRouter>
//           <App />
//         </BrowserRouter>
//       </ApolloProvider>
//     </PersistGate>
//   </Provider>,
//   document.getElementById('app')
// );

import React, { useEffect, useState } from 'react';
import { render } from 'react-dom';
import { useHistory } from 'react-router-dom';

import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react/context';
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
import { Provider, useDispatch, shallowEqual, useSelector } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import loadable from '@loadable/component';
import axios from 'axios';

const Home = loadable(() => import('./pages/NewHome/'));
const EventProperties = loadable(() => import('./pages/EventProperties/'));
const PostMedia = loadable(() => import('./pages/PostMedia/'));
const Profile = loadable(() => import('./pages/Profile/'));
const Gift = loadable(() => import('./pages/Gift/'));
const PostAudio = loadable(() => import('./pages/PostAudio/'));
const Register = loadable(() => import('./pages/Register/'));
const PostAvatar = loadable(() => import('./pages/PostAvatar/'));
const AllReplies = loadable(() => import('./pages/AllReplies/'));
const PrivacyPolicy = loadable(() => import('./pages/PrivacyPolicy/'));
const GuestList = loadable(() => import('./pages/GuestList/'));
const Reactions = loadable(() => import('./pages/Reactions/'));
const Post = loadable(() => import('./pages/Post/'));

const Spinner = loadable(() => import('./components/Spinner'));

import { apolloClient } from './apollo';

import store from './redux/store';
import { setEventId } from './redux/actions/event';
import { setCurrentUser, unsetCurrentUser } from './redux/actions/users';

import { getEventId } from './redux/selectors/event';
import { getCurrentUser } from './redux/selectors/users';
import { setCurrentCookies } from './redux/actions/cookies';
import { getCurrentCookies } from './redux/selectors/cookies';

import {
  USER_SIMPLE_SIGN_ON,
  CONFIRM_USER_EMAIL,
  UPDATE_USER_PROFILE,
} from './apollo/user/mutations';
import { EVENT_PROMT_ADDED } from './apollo/events/subscripitons';

import { setAuthCookies } from './helpers/general';

import { ROUTES } from './lib/constants/routes';
import { CLIENT_CONSTANTS } from './lib/constants/client';
import { FILE_FOLDERS_CONSTANTS } from './lib/constants/fileFolders';

import { getFileUploadURL } from './helpers/files';
import { detectSafari } from './helpers/detectSafari';
import useDetectIos from './hooks/useDetectIos';
import { getCookie } from './helpers/general';

import { CREATE_EVENT_FEED } from './apollo/events/mutations';
import { EVENT_PROMTS, EVENT_BY_LINK } from './apollo/events/queries';

const { TOKEN, PROMT_TYPE, PROMT_ID, PROMT_TEXT, AUDIO_PROMT, GIFT_PROMT } =
  CLIENT_CONSTANTS;

import '/styles/reset.scss';
import '/styles/globals.scss';

const {
  POST,
  ROOT,
  EVENT_PROPERTIES,
  POST_MEDIA,
  PROFILE,
  GIFT,
  REGISTER,
  VERIFY_EMAIL,
  POST_AUDIO,
  POST_AVATAR,
  ALL_REPLIES,
  PRIVACY_POLICY,
  GUEST_LIST,
  REACTIONS,
} = ROUTES;

const App = () => {
  const dispatch = useDispatch();
  const [confirmUserEmail] = useMutation(CONFIRM_USER_EMAIL);
  const [userSimpleSignOn] = useMutation(USER_SIMPLE_SIGN_ON);
  const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE);
  const [createEventFeed] = useMutation(CREATE_EVENT_FEED);

  const [loadedPercent, setLoadedPercent] = useState(0);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showFailedToast, setShowFailedToast] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [postFiles, setPostFiles] = useState();
  const [postFilesSize, setPostFilesSize] = useState();
  const [postFilesLoadedSize, setPostFilesLoadedSize] = useState(0);
  const [postText, setPostText] = useState();
  const [promtId, setPromtId] = useState();
  const [promtText, setPromtText] = useState('');
  const [unrepliesPromptsCount, setUnrepliesPromptsCount] = useState();
  const [incognitoMode, setIncognitoMode] = useState(false);
  const [promtType, setPromtType] = useState('');
  const [showAudioPopup, setShowAudioPopup] = useState(false);
  const [retryClicked, setRetryClicked] = useState(false);
  const [showFirstPostModal, setShowFirstPostModal] = useState(true);

  const isSafari = detectSafari();
  const isIos = useDetectIos();
  const isIosOrSafari = isSafari || isIos;

  const url = new URL(decodeURIComponent(window.location));

  const query = new URLSearchParams(url.search);
  const { push } = useHistory();

  const promtTypeFromUrl = query.get(PROMT_TYPE);
  const promtIdFromUrl = query.get(PROMT_ID);
  const promtTextFromUrl = query.get(PROMT_TEXT);
  const tokenFromUrl = query.get(TOKEN);

  const correctMutationSetToken =
    tokenFromUrl && window.location.pathname === VERIFY_EMAIL
      ? confirmUserEmail
      : null;

  const eventId =
    url.pathname.split('/').length === 3 &&
    url.pathname.includes('wedding') &&
    !url.pathname.includes('addEventFeed') &&
    !url.pathname.includes('guest-list')
      ? url.pathname.split('/').slice(-1)[0]
      : url.pathname.split('/').length === 3 &&
        url.pathname.split('/')[2] === 'guest-list'
        ? push(`${GUEST_LIST}/${url.pathname.split('/')[1]}`)
        : url.pathname.split('/').length === 2 &&
          ![
            ROOT,
            EVENT_PROPERTIES,
            POST_MEDIA,
            PROFILE,
            GIFT,
            REGISTER,
            VERIFY_EMAIL,
            POST_AUDIO,
            POST_AVATAR,
            ALL_REPLIES,
            PRIVACY_POLICY,
            GUEST_LIST,
          ].includes(url.pathname)
          ? url.pathname.split('/').slice(-1)[0]
          : url.pathname.split('/').length === 3 &&
            url.pathname.includes('addEventFeed')
            ? url.pathname.split('/').slice(1)[0]
            : url.pathname.split('/').length === 4 && url.pathname.includes('event')
              ? url.pathname.split('/')[2]
              : '';

  const eventIdFromRedux = useSelector(getEventId, shallowEqual);
  const userFromRedux = useSelector(getCurrentUser, shallowEqual);
  const cookiesFromRedux = useSelector(getCurrentCookies, shallowEqual);

  const token = getCookie('token') || '';

  useEffect(() => {
    if (userFromRedux && !token && cookiesFromRedux) {
      setAuthCookies(cookiesFromRedux);
    }
  }, [userFromRedux, token, cookiesFromRedux]);

  const { data: { eventPromts } = {}, refetch: refetchPromptsCount } = useQuery(
    EVENT_PROMTS,
    {
      skip: !eventIdFromRedux || !userFromRedux,
      variables: {
        eventId: eventIdFromRedux,
      },
    }
  );

  useEffect(() => {
    if (eventPromts && userFromRedux) {
      const unrepliesPrompts = eventPromts.filter(
        ({ doneBy, type }) =>
          !doneBy.includes(userFromRedux._id) && type !== 'Text'
      ).length;

      if (unrepliesPrompts > 0) {
        setUnrepliesPromptsCount(unrepliesPrompts);
      } else {
        setUnrepliesPromptsCount(null);
      }
    }
  }, [eventPromts, userFromRedux]);

  const { data: { eventPromtAdded } = {} } = useSubscription(
    EVENT_PROMT_ADDED,
    {
      skip: !eventIdFromRedux || !userFromRedux,
      variables: { eventId: eventIdFromRedux },
    }
  );

  useEffect(() => {
    if (eventPromtAdded) {
      if (unrepliesPromptsCount > 0) {
        setUnrepliesPromptsCount(unrepliesPromptsCount + 1);
      } else {
        setUnrepliesPromptsCount(1);
      }
    }
  }, [eventPromtAdded]);

  let loadedDataPerItem = 0;
  let loadedItems = 0;

  const onUploadProgress = (progressEvent) => {
    const { loaded, total } = progressEvent;
    const percentCurrent = Math.floor((loaded * 100) / total);

    if (percentCurrent < 100) {
      setShowFailedToast(false);
      setPostFilesLoadedSize((prev) =>
        loadedItems ? loaded + prev - loadedDataPerItem : loaded
      );
      loadedDataPerItem = loaded;
    } else if (percentCurrent === 100) {
      setPostFilesLoadedSize((prev) =>
        loadedItems ? loaded + prev - loadedDataPerItem : loaded
      );
      loadedItems = loadedItems + 1;
      loadedDataPerItem = 0;
    }
  };

  useEffect(() => {
    if (postFilesLoadedSize && postFilesSize) {
      setLoadedPercent(Math.floor((postFilesLoadedSize * 100) / postFilesSize));
    }
  }, [postFilesLoadedSize, postFilesSize]);

  const runSignedURLUpload = ({ uploadUrl, file, contentType }) => {
    return axios
      .put(uploadUrl, file, {
        headers: {
          'Content-Type': contentType,
        },
        onUploadProgress,
      })
      .catch(() => setShowFailedToast(true));
  };

  const onSubmitMedia = async (e) => {
    if (e.target) {
      e?.preventDefault();
    }
    setPromtType('');
    setPromtText('');

    const files =
      postFiles || (e?.target?.files ? Array.from(e.target.files) : [e]);

    const path = files[0]?.type.includes('image')
      ? FILE_FOLDERS_CONSTANTS.images
      : files[0]?.type.includes('audio')
        ? FILE_FOLDERS_CONSTANTS.audio
        : FILE_FOLDERS_CONSTANTS.video;

    const mediaData = files?.map((file) => ({
      contentType: file?.type,
      fileName: file?.name?.replaceAll(' ', '_'),
      path: path,
    }));

    const filesUploadedData = await getFileUploadURL(mediaData);

    const fileIds = filesUploadedData?.map(({ file }) => file?._id);

    var didUpload = false;
    const putData = async () => {
      for (let i = 0; i < filesUploadedData.length; i++) {
        await axios
          .put(filesUploadedData[i].putUrl, files[i], {
            headers: {
              'Content-Type': files[i]?.type,
            },
            onUploadProgress,
          }).then((x) => {if(x.status==200){didUpload=true}})
          .catch(() => setShowFailedToast(true));
      }
    };

    await putData();

    if (didUpload)
    {
      await createEventFeed({
        variables: {
          eventId: eventIdFromRedux,
          text: postText,
          fileIds: fileIds,
          promtId: promtId,
        },
      })
        .then(() => {
          setLoadedPercent(0);
          setRetryClicked(false);
          setPostFilesLoadedSize(0);
          setShowSuccessToast(true);
          setShowFailedToast(false);
          refetchPromptsCount();
          setPostText();
          setPromtId();
          setPostFiles();
        })
        .catch(({ message }) => {
          console.error(message);
        });
    }
  };

  useEffect(() => {
    if (showSuccessToast) {
      setTimeout(() => {
        setShowSuccessToast(false);
      }, 3000);
    }
  }, [showSuccessToast]);

  const onSubmitAvatar = async (e) => {
    e.preventDefault();

    if (postFiles?.length) {
      setShowFirstPostModal(false);
      const { name, type } = postFiles[0];
      const correctName = name.replaceAll(' ', '_');
      const data = await getFileUploadURL([
        {
          contentType: type,
          fileName: correctName,
          path: FILE_FOLDERS_CONSTANTS.images,
        },
      ]);

      await runSignedURLUpload({
        uploadUrl: data[0]?.putUrl,
        contentType: type,
        file: postFiles[0],
      });

      await updateUserProfile({
        variables: {
          avatar: data[0]?.file?.url,
        },
      })
        .then(({ data }) => {
          if (data) {
            dispatch(setCurrentUser({ ...data?.updateUserProfile }));
          }
        })
        .catch(({ message }) => console.error(message));

      await createEventFeed({
        variables: {
          eventId: eventIdFromRedux,
          text: postText,
          fileIds: [data[0]?.file?._id],
        },
      })
        .then(() => {
          setPostText();
          setPostFiles();
          setLoadedPercent(0);
          setRetryClicked(false);
          setPostFilesLoadedSize(0);
          setShowSuccessToast(true);
          setShowFailedToast(false);
          if (promtType === AUDIO_PROMT) {
            push(POST_AUDIO);
          } else if (promtType === GIFT_PROMT) {
            push(GIFT);
          }
        })
        .catch(({ message }) => {
          console.error(message);
        });
    }
  };

  useEffect(async () => {
    if (
      [3, 4].includes(url.pathname.split('/').length) &&
      promtTypeFromUrl &&
      promtIdFromUrl
    ) {
      setPromtId(promtIdFromUrl);
      setPromtText(promtTextFromUrl);
      setPromtType(promtTypeFromUrl);

      if (userFromRedux?.profile?.avatar && eventIdFromRedux === eventId) {
        setShowFirstPostModal(false);
        setIsLoading(false);

        if (promtTypeFromUrl === AUDIO_PROMT) {
          setShowAudioPopup(true);
        } else if (promtTypeFromUrl === GIFT_PROMT) {
          push(GIFT);
        }
      } else if (
        userFromRedux?.profile?.avatar &&
        eventIdFromRedux !== eventId
      ) {
        await apolloClient
          .query({
            query: EVENT_BY_LINK,
            variables: { eventLink: eventId },
          })
          .then(({ data: { eventByLink } = {} }) => {
            if (eventByLink) {
              dispatch(setEventId(eventByLink?._id));
            } else {
              dispatch(setEventId(eventId));
            }
            userSimpleSignOn({
              variables: {
                name: `${userFromRedux.profile.firstName} ${userFromRedux.profile.lastName}`,
                eventId: eventByLink ? eventByLink?._id : eventId,
              },
            })
              .then(({ data }) => {
                const { user, token } = data?.userSimpleSignOn || {};
                if (user) {
                  updateUserProfile({
                    variables: {
                      avatar: userFromRedux.profile.avatar,
                    },
                  })
                    .then(({ data }) =>
                      dispatch(setCurrentUser(data?.updateUserProfile))
                    )
                    .catch(({ message }) => {
                      console.error(message);
                    })
                    .finally(() => setIsLoading(false));
                }
                if (token) {
                  setAuthCookies(token);
                  dispatch(setCurrentCookies(token));
                  refetchPromptsCount();
                  setShowFirstPostModal(false);
                  if (promtTypeFromUrl === AUDIO_PROMT) {
                    setShowAudioPopup(true);
                  } else if (promtTypeFromUrl === GIFT_PROMT) {
                    push(GIFT);
                  }
                  setIsLoading(false);
                }
              })
              .catch(({ message }) => {
                console.error(message);
                setIsLoading(false);
              });
          });
      } else if (!userFromRedux?.profile?.avatar) {
        await apolloClient
          .query({
            query: EVENT_BY_LINK,
            variables: { eventLink: eventId },
          })
          .then(({ data: { eventByLink } = {} }) => {
            if (eventByLink) {
              dispatch(setEventId(eventByLink?._id));
            } else {
              dispatch(setEventId(eventId));
            }
            setIsLoading(false);
          });
      }
    } else if (!eventIdFromRedux && eventId) {
      await apolloClient
        .query({
          query: EVENT_BY_LINK,
          variables: { eventLink: eventId },
        })
        .then(({ data: { eventByLink } = {} }) => {
          if (eventByLink) {
            dispatch(setEventId(eventByLink?._id));
          } else {
            dispatch(setEventId(eventId));
          }
          setIsLoading(false);
        });
    } else if (eventIdFromRedux && eventId && userFromRedux?.profile?.avatar) {
      setShowFirstPostModal(false);
      await apolloClient
        .query({
          query: EVENT_BY_LINK,
          variables: { eventLink: eventId },
        })
        .then(({ data: { eventByLink } = {} }) => {
          if (eventByLink) {
            dispatch(setEventId(eventByLink?._id));
          } else {
            dispatch(setEventId(eventId));
          }
          userSimpleSignOn({
            variables: {
              name: `${userFromRedux.profile.firstName} ${userFromRedux.profile.lastName}`,
              eventId: eventByLink ? eventByLink?._id : eventId,
            },
          })
            .then(({ data }) => {
              const { user, token } = data?.userSimpleSignOn || {};
              if (user) {
                updateUserProfile({
                  variables: {
                    avatar: userFromRedux.profile.avatar,
                  },
                })
                  .then(({ data }) =>
                    dispatch(setCurrentUser(data?.updateUserProfile))
                  )
                  .catch(({ message }) => {
                    console.error(message);
                  })
                  .finally(() => setIsLoading(false));
              }
              if (token) {
                setAuthCookies(token);
                dispatch(setCurrentCookies(token));
                refetchPromptsCount();
                setIsLoading(false);
              }
            })
            .catch(({ message }) => {
              console.error(message);
              setIsLoading(false);
            });
        });
    } else if (
      eventIdFromRedux &&
      eventId &&
      eventIdFromRedux !== eventId &&
      !userFromRedux?.profile?.avatar
    ) {
      await apolloClient
        .query({
          query: EVENT_BY_LINK,
          variables: { eventLink: eventId },
        })
        .then(({ data: { eventByLink } = {} }) => {
          if (eventByLink) {
            dispatch(setEventId(eventByLink?._id));
          } else {
            dispatch(setEventId(eventId));
          }
          setIsLoading(false);
        });
    } else if (eventIdFromRedux && !userFromRedux?.profile?.avatar) {
      dispatch(unsetCurrentUser());
    } else if (tokenFromUrl && window.location.pathname === VERIFY_EMAIL) {
      correctMutationSetToken({
        variables: {
          token: tokenFromUrl,
        },
      })
        .then(({ data }) => {
          const currentUser = data?.confirmUserEmail;
          if (currentUser) {
            const { user, token } = currentUser || {};
            setAuthCookies(token);
            dispatch(setCurrentUser(user));

            location.replace(window.location.pathname);
            setIsLoading(false);
          }
        })
        .catch(({ message }) => {
          console.error(message);
          setIsLoading(false);
        });
    } else if (userFromRedux?.profile?.avatar) {
      setShowFirstPostModal(false);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }

    // check incognito mode in chrome
    if ('estimate' in navigator.storage && !userFromRedux?.profile?.avatar) {
      navigator.storage.estimate().then(({ quota }) => {
        if (quota < 1200000000) {
          setIncognitoMode(true);
        } else {
          setIncognitoMode(false);
        }
        setIsLoading(false);
      });
    } else if (
      // check incognito mode in safari
      'getDirectory' in navigator.storage &&
      !userFromRedux?.profile?.avatar
    ) {
      navigator.storage.getDirectory().then(
        () => {
          setIncognitoMode(false);
        },
        () => {
          setIncognitoMode(true);
        }
      );
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Switch>
      <Route exact path={ROOT}>
        <Home
          {...{
            isIosOrSafari,
            loadedPercent,
            unrepliesPromptsCount,
            incognitoMode,
            setIncognitoMode,
            promtType,
            setPromtType,
            setPromtText,
            setPromtId,
            promtText,
            promtId,
            showAudioPopup,
            setShowAudioPopup,
            showFirstPostModal,
            onSubmitMedia,
            setShowFirstPostModal,
            showSuccessToast,
            setShowFailedToast,
            showFailedToast,
            setPostFilesSize,
            setLoadedPercent,
            retryClicked,
            setRetryClicked,
          }}
        />
      </Route>
      <Route path={EVENT_PROPERTIES}>
        <EventProperties />
      </Route>
      <Route path={PROFILE}>
        <Profile />
      </Route>
      <Route path={POST_AVATAR}>
        <PostAvatar
          {...{
            postFiles,
            setPostFiles,
            onSubmitAvatar,
            setPostText,
            postText,
            setPostFilesSize,
          }}
        />
      </Route>
      <Route path={POST_MEDIA}>
        <PostMedia
          {...{
            postFiles,
            postText,
            setPostFiles,
            setPostText,
            onSubmitMedia,
            setPromtId,
            setPostFilesSize,
          }}
        />
      </Route>
      <Route path={GIFT}>
        <Gift
          {...{
            refetchPromptsCount,
            promtText,
            promtId,
            setPromtText,
            setPromtId,
          }}
        />
      </Route>
      <Route path={`${ALL_REPLIES}/:id`}>
        <AllReplies {...{ isIosOrSafari, setPromtId, setPromtText }} />
      </Route>
      <Route path={`${REACTIONS}/:id`}>
        <Reactions />
      </Route>
      <Route path={POST_AUDIO}>
        <PostAudio
          {...{
            setPostFiles,
            onSubmitMedia,
            setPostFilesSize,
          }}
        />
      </Route>
      <Route path={REGISTER}>
        <Register />
      </Route>
      <Route path={`${GUEST_LIST}/:id`}>
        <GuestList />
      </Route>
      <Route path={`${POST}/:id`}>
        <Post isIosOrSafari={isIosOrSafari} />
      </Route>
      <Route path={PRIVACY_POLICY}>
        <PrivacyPolicy />
      </Route>
      <Route path="*">
        <Redirect to={ROOT} />
      </Route>
    </Switch>
  );
};

const persistor = persistStore(store, {}, function () {
  persistor.persist();
});

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ApolloProvider client={apolloClient}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApolloProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('app')
);
