import { SET_CURRENT_COOKIES } from '../actions/cookies';

const initialState = {
  cookies: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
  case SET_CURRENT_COOKIES:
    return { ...state, cookies: action.payload.cookies };
  default:
    return state;
  }
}
