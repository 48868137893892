export const CLIENT_CONSTANTS = {
  TOKEN: 'token',
  VIDEO_PROMT: 'Video',
  AUDIO_PROMT: 'VoiceNote',
  PHOTO_PROMT: 'Photo',
  GIFT_PROMT: 'Gift',
  PROMT_TEXT: 'promtText',
  PROMT_TYPE: 'promtType',
  PROMT_ID: 'promtId',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  SHOW_GIFT_POPUP: 'show_gift_popup',
  PUBLIC: 'public',
  TO_LOGIN: 'to_login',
  PARTNER_CODE: 'partner-code',
  GET_CALENDAR_EVENT: 'getCalendarEvent',
  CONNECTED_EVENT_LINK: 'CONNECTED_EVENT_LINK',
  PARTNERSHIP_CODE: 'PARTNERSHIP_CODE',
};
