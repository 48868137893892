import axios from 'axios';
import { apolloClient } from '../apollo';
import { GET_SIGNED_URLS } from '../apollo/files/queries';

const onUploadProgress = (progressEvent: { loaded: any; total: any }) => {
  // const element = document.getElementById('progress');

  const { loaded, total } = progressEvent;
  let percent = Math.floor((loaded * 100) / total);
  if (percent < 100) {
    // if (element) element.innerText = percent.toString();
  }
};

export const runSignedURLUpload = ({ uploadUrl, file, contentType }) => {
  return axios.put(uploadUrl, file, {
    headers: {
      'Content-Type': contentType,
    },
    onUploadProgress,
  });
};

/**
 * Returns URL to fetch image and URL to upload image via axio
 * @param {string} fileName
 * @param {string} contentType
 * @param {string} path
 * @return {Promise<{fileUrl: string, putUrl: string, fileId: string}>}
 */

interface contentType {
  fileName: string;
  contentType: string;
  path: string;
}

export const getFileUploadURL = async (props: contentType[]) => {
  const { data } = await apolloClient.query({
    query: GET_SIGNED_URLS,
    variables: {
      files: props,
    },
  });
  return data.getSignedUrls;
};
