import gql from 'graphql-tag';

export const FullEventFragment = gql`
  fragment FullEventFragment on Event {
    _id
    title
    location
    date
    host
    startDate
    groups
    isWhatsApp
    endDate
    coHosts
    publicLink
    timezone
    guests
    weddingOf
    hotels
    airport
    receptionAddress
    ceremonyAddress
    guestListLink
    polaroidOrderFormLink
    memoryBoxOrderFormLink
    allowGifting
    showGiftingAmount
    registryLink
    eventLink
    eventLinks
    tags
    createdAt
    eventPromts {
      _id
      eventId
      doneBy
      day
      type
      hour
      deleted
      isActive
      text
      createdAt
      friendlyHour
      isWelcome
      group
    }
    locations {
      name
      address
    }
    itinerary {
      name
      timeFrom
    }
    weddingOfData {
      email
      userName
      avatar
    }
    coHostsData {
      email
      avatar
      userName
      phoneNumber
    }
    coHostUserProfiles {
      _id
      roles
      profile {
        firstName
        email
        avatar
        phoneNumber
      }
    }
    hostUserProfile {
      _id
      roles
      profile {
        avatar
        firstName
        lastName
        email
        phoneNumber
      }
    }
    tablesData {
      _id
      title
      membersData {
        roles
        email
        userName
        phoneNumber
        addressLine1
        avatar
      }
    }
    weddingOfUserProfiles {
      _id
      roles
      profile {
        firstName
        lastName
        avatar
        email
        phoneNumber
      }
    }
  }
`;

export const EventCalendarFragment = gql`
  fragment EventCalendarFragment on Event {
    _id
    title
    date
    endDate
    host
    coHosts
    weddingOf
    guests
    status
    timezone
    priceToJoin
    publicLink
    about
    isDraft
    coverScreenImageUrl
    paymentGiftsCount
    tags
    location
    eventLink
    eventLinks
    createdAt
    sponsor {
      name
    }
    hostUserProfile {
      profile {
        firstName
        lastName
        email
        phoneNumber
      }
    }
    weddingOfUserProfiles {
      profile {
        firstName
        lastName
        phoneNumber
      }
    }
  }
`;

export const EventListFragment = gql`
  fragment EventListFragment on Event {
    _id
    isDraft
    title
    banner
    date
    status
    location
    guests
    host
    coverScreenImageUrl
    sponsor {
      name
    }
  }
`;

export const EventLiveBannerFragment = gql`
  fragment EventLiveBannerFragment on Event {
    _id
    title
    banner
    status
    location
    guests
    date
    sponsor {
      name
    }
  }
`;
