import { ApolloClient } from '@apollo/client/core/ApolloClient';
import { from, split } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { HttpLink } from '@apollo/client/link/http';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { getCookie } from '../helpers/general';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

const { WEBSOCKET_URL, URL } = process.env;

export const FETCH_POLICY = {
  CACHE_FIRST: 'cache-first',
  CACHE_AND_NETWORK: 'cache-and-network',
  NETWORK_ONLY: 'network-only',
  CACHE_ONLY: 'cache-only',
  NO_CACHE: 'no-cache',
  STANDBY: 'standby',
};

export const wsLink = new WebSocketLink({
  uri: WEBSOCKET_URL,
  options: {
    lazy: true,
    timeout: 30000,
    reconnect: true,
    connectionParams: function () {
      const token = getCookie('token') || '';
      return {
        authorization: token ? `Bearer ${token}` : '',
      };
    },
  },
});

const authLink = setContext((_, req) => {
  // get the authentication token from cookies if it exists
  const token = getCookie('token') || '';
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...req.headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      // Exclude currentUser not logged in error
      if (path?.toString() !== 'currentUser') {
        console.error(
          `[GraphQL Error]: Message: ${message}, Location: ${JSON.stringify(
            locations
          )}, Path: ${path}`
        );
      }
    });

  if (networkError)
    console.error(`[Network error]: ${JSON.stringify(networkError)}`);
});

const httpLink = new HttpLink({
  uri: `${URL}/graphql`,
  credentials: 'same-origin',
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

export const apolloClient = new ApolloClient({
  link: from([errorLink, authLink, splitLink]),
  credentials: 'include',
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: {
    query: {
      fetchPolicy: FETCH_POLICY.NO_CACHE,
    },
    watchQuery: {
      fetchPolicy: FETCH_POLICY.NO_CACHE,
    },
    mutate: {
      fetchPolicy: FETCH_POLICY.NO_CACHE,
    },
  },
});
